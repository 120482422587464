div.imgContainer {
    float: left;
    width: 100%;
    background-image: url('../../imgs/punk5px.png');
    position: relative;
    background-position: center;
    background-size: cover;
  }
div.imgContainer:before {
  content: "";
  display: block;
  padding-top: 100%;
  float: left;
}
div.imgContainerBlue {
  float: left;
  width: 100%;
  background-image: url('../../imgs/BlueDot.png');
  position: relative;
  background-position: center;
  background-size: cover;
}

div.imgContainerBlue:before {
  content: "";
  display: block;
  padding-top: 100%;
  float: left;
}